import React from "react"
import EventLayout from "../../../components/layouts/eventLayout"

import Chamber from "../../../images/2024CruisinRockandRollFlyer.png"

const CurisinInTheCountryIndexPage = props => (
  <EventLayout
    pageTitle="Cruisin’ in the Country"
    tabs={[
      { to: "/programs/cruisin-in-the-country", text: "Overview" },
      {
        to: "/programs/cruisin-in-the-country/routes",
        text: "Routes",
      },
      {
        to: "/programs/cruisin-in-the-country/registration",
        text: "Registration",
      },
      {
        to: "/programs/cruisin-in-the-country/accomodations",
        text: "Accommodations",
      },
     /* {
        to: "/programs/cruisin-in-the-country/run",
        text: "Run",
      },
      */
    ]}
  >

    <img
        src={Chamber}
        css={{
          display: "inline-block",
          border: "1px solid #000",
          width: "50%",
          float: "left",
          marginRight: "1rem",
          marginBottom: `1rem`,
          "@media (min-width: 800px)": {
            width: `45%`,
            marginBottom: `0`,
          },
        }}
      />
    
    <p css={{fontSize: `1.5rem`, lineHeight: `1`, margin: `3rem 0 0 0`, padding: `0`}}>	28<sup>th</sup> Annual</p>
    <h2 css={{fontSize: `2rem`}}>Cruisin’ in the Country Bicycle Ride</h2>
    <p css={{fontSize: `1.25rem`, lineHeight: `1`, margin: `0 0 2rem 0`, padding: `0`}}><em>Saturday, November 9, 2024</em></p>
    
    <p>
      The Claxton-Evans County Chamber of Commerce is proud to sponsor the
      28th Annual Cruisin’ in the Country Bicycle Ride, Saturday, November 9th, 2024.  
    </p>
    <br />
    <p>
      Cruisin’ Headquarters has moved this year. This year’s event will be headquartered at the Evans County
      Recreation Department located 1.2 miles east of Claxton on Highway 280. Physical Address – 11093 Highway
      280 East, Claxton, Georgia 30417. Some may remember that this is where Cruisin’ was hosted for many
      years prior to the move to the Evans County Public Fishing Area in 2021.
    </p>

    <h3 css={{fontSize: `1.25rem`, margin: `2rem 0 0.5rem 0`}}>Directions</h3>
    <p>
        The Evans County Recreation Department, Cruisin’ Headquarters, is located 1.2 miles east of Claxton
        on Highway 280. Physical Address – 11093 Highway 280 East, Claxton, Georgia 30417.
        Cruisin’ in the Country Ride will be held rain or shine. Registration fees are non-refundable. The ride
        will not be rescheduled due to weather conditions.
    </p><br /><br />
    <p>Follow Cruisin’ on Facebook (<a href="https://www.facebook.com/cruisininthecountry">www.facebook.com/cruisininthecountry</a>) for special announcements, contests, and current Ride Information.</p><br />
    <p>For additional information, please contact the Claxton-Evans County Chamber of Commerce, <a href="mailto:info@claxtonevanschamber.com">info@claxtonevanschamber.com</a>, or (912) 739-1391.</p>


    <h3 css={{fontSize: `1.25rem`, margin: `3rem 0 0rem 0`}}>Schedule of Events</h3><br />
    <p>
      <b css={{textDecoration: `underline`}}>Friday, November 8</b><br />
      2:00 pm - Ride Headquarters opens for packet pick-up and on-site registration<br />
      2:00 pm to 5:00 pm - Informal/Warm-up Ride.  Maps available at Registration<br />
      5:00 pm to 7:00 pm – Pasta Social  
    </p><br />
    <p>
      <b css={{textDecoration: `underline`}}>Saturday, November 9</b><br />
        7:00 am – Registration Opens<br />
        7:00 am to 9:00 am – Hospitality Booth Open <br />
        7:30 am – Show & Go Start.  (All cyclists are <em>strongly encouraged</em> to begin their route before 9:00 a.m.) <br />
        8:00 am – First Rest Stop Opens<br />
        11:00 am until 6:00 pm – Post Ride Meal @ Cruisin’ Headquarters<br />
        5:00 pm – Last Rest Stop Closes
    </p><br />
    <p>
      Be sure to visit our local restaurants and attractions while you are “Cruisin’ in the Country”.  Copies of menus and 
      information on attractions and points of interest are available for pick-up at the Hospitality Booth. 
    </p>
    <br /><br />
<hr />

    <h2 css={{fontSize: `1.25rem`, margin: `3rem 0 0rem 0`}}>Cruisin’ Sponsorship Opportunities</h2><br />

    <p><b>Why sponsor the 28th Annual Cruisin’ in the Country Cycling Event?</b></p>
    <ul css={{marginLeft: `3rem`}}>
      <li>Exposure for your business in Evans County and the surrounding areas.</li>
      <li>Advertising Opportunity – your name and logo will circulate as a partner throughout our extensive event advertising campaign (social media, print material, t-shirts, banners, etc.)</li>
      <li>Community involvement and recognition for your company for giving back to the community.</li>
      <li>Your participation will help encourage economic growth within the community and surrounding areas.</li>
    </ul><br />

    <p><b>For your Sponsorship of only $250, you will receive</b></p>
    <ul css={{marginLeft: `3rem`}}>
      <li>Name and logo prominently printed on the back of the official Cruisin’ t-shirt</li>
      <li>Opportunity to display company banner at Cruisin’ Headquarters</li>
      <li>Opportunity to include items in goody bags</li>
      <li>Complimentary vendor space available to display and/or sell products at Cruisin’ Headquarters</li>
      <li>Company name and logo included on Cruisin’ in the Country homepage with a website link</li>
      <li>2 Complimentary Cruisin’ registrations</li>
    </ul>
<br /><br />
    <a css={{
        display: "inline-block",
        marginTop: "0.66rrem",
        backgroundColor: "rgb(9, 37, 86)",
        color: "#fff",
        textDecoration: "none",
        padding: "0.667rem",
        borderRadius: "2px",
        marginRight: `1.5rem`,
      }} href="/docs/28thAnnualCruisinSponsorshipForm.pdf">Sponsorship Application</a>
  </EventLayout>
)

export default CurisinInTheCountryIndexPage
